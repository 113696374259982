const navEl = document.getElementById("nav");

const observer = new IntersectionObserver(
  function (entries, { rootMargin }) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        navEl.classList.toggle("text-main-black", false);
        navEl.classList.toggle("text-white", true);
      } else {
        navEl.classList.toggle("text-main-black", true);
        navEl.classList.toggle("text-white", false);
      }
    });
  },
  { rootMargin: "-7% 0px -93% 0px" },
);

const darkSectionEl = document.getElementById("darkSection");
observer.observe(darkSectionEl);
